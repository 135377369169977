import React from "react"
import Layout from "../modules/layout"
import SEO from "../modules/seo"
import Intro from "../components/intro"
import FourFeatures from "../components/fourFeatures"
import DownloadInStores from "../components/downloadInStores"
import CoreFeature from "../components/coreFeature"
import FullScreenImage from "../components/fullScreenImage"
import feature1 from "../images/appshots/feature-1.png"
import feature2 from "../images/appshots/feature-2.png"
import feature3 from "../images/appshots/feature-3.png"
import feature4 from "../images/appshots/feature-4.png"
import feature5 from "../images/appshots/feature-5.png"
import AppVideo from "../components/appVideo"
import StartNow from "../components/startNow"
import SuccessStories from "../components/successStories"

const IndexPage = () => {
  return (
    <Layout theme="smartwerker" lang="en">
      <SEO title="Smartwerker" />
      <div id="about"/>
      <Intro
        titleColored="Smartwerker."
        title="The Craftsmen App"
        description="Record timesheets digitally, exchange information via chat, store and share documents, create tasks and much more!"
        btnLabel="Test now for free"
      />
      <div id="features"/>
      <FourFeatures 
        title1="Tasks"
        description1="Create tasks and assign them to your colleagues. Completed tasks are checked off and nothing is forgotten."
        title2="Material"
        description2="With loading lists, there is no longer any shortage of material at the customer's site. The installed material can be viewed at any time."
        title3="Time recording"
        description3="No more waiting for time sheets from colleagues. Hours worked are listed in real time per project."
        title4="Images and documents"
        description4="Plans, construction progress and delivery notes are stored digitally for the project. This means that everything relevant can be found in one place at any time."
      />
      <DownloadInStores 
        title="THE #1 APP FOR CRAFTSMEN ALSO IN THE BROWSER"
        description="Smartwerker connects the colleagues in the office and at the customer's site. All necessary information (documents, material, timesheets, reports, pictures) and chat messages can be shared and accessed within the mobile app and in the browser - in real time."
      />
      <CoreFeature 
        additionalClass="ml-minus"
        category="FUNCTION"
        title="Projects"
        description="Create a project in Smartwerker to complete your order. This allows you to use all functions of Smartwerker and always keep a good overview of your projects/orders."
        btnLabel="Learn more"
        btnTarget="https://smartwerker.app/"
        img={feature1}
      />
      <CoreFeature 
        additionalClass=""
        category="FUNCTION"
        title="Create and sign reports"
        description="Enter the hours worked and the material consumed in a report. Once the report has been created, it can be sent to the customer by e-mail and even digitally signed on site."
        btnLabel="Learn more"
        btnTarget="https://smartwerker.app/"
        img={feature2}
      />
      <CoreFeature 
        additionalClass="ml-minus"
        category="FUNCTION"
        title="Chat and voice messages"
        description="The exchange via chat with the colleagues from the smartphone or PC is no problem and of course DSGVO compliant."
        btnLabel="Learn more"
        btnTarget="https://smartwerker.app/"
        img={feature3}
      />
      <CoreFeature 
        additionalClass=""
        category="FUNCTION"
        title="Time recording"
        description="Record all hours worked on a project. The access to the recorded times in real time, gives you the possibility to prepare invoices in the office during the working day."
        btnLabel="Learn more"
        btnTarget="https://smartwerker.app/"
        img={feature4}
      />
      <FullScreenImage
        additionalClass=""
        img={feature5}
      />
      <AppVideo 
        title="Smartwerker in Action"
        description="See how Smartwerker can help you and your company."
        videoId="hTD4uRX31Xc"
      />
      <div id="start"/>
      <StartNow 
        title="Test now for free"
        description="Start now in just a few steps. and digitize your craft business! Use Smartwerker in the browser, on the smartphone or on the tablet. Download our app now from the Apple App Store or the Google Play Store."
        btnLabel="RELEASE NOW"
      />
      <div id="testimonials"/>
      <SuccessStories 
        title="Success Stories"
      />
    </Layout>
  )
}

export default IndexPage

// export const query = graphql`
// query indexHomeQuery {
//   allContentfulSmartwerkerHome {
//     nodes {
//       id
//       title
//       headerImage {
//         fluid(maxWidth: 1600 quality: 50) {
//           src
//           srcSet
//           srcWebp
//           srcSetWebp
//           sizes
//           aspectRatio
//         }
//         description
//       }
//       components {
//         ... on ContentfulContentTeaser {
//           id
//           contenTeaserTitle
//           contenTeaserContent {
//             json
//           }
//           contenTeaserCarousel {
//             fluid {
//               src
//               srcSet
//               srcWebp
//               srcSetWebp
//               sizes
//               aspectRatio
//             }
//           }
//         }
//         ... on ContentfulPromotionTeaser2 {
//           id
//           promotionTeaserTitle
//           promotionTeaserImageRightTop {
//             fluid {
//               src
//               srcSet
//               srcWebp
//               srcSetWebp
//               sizes
//               aspectRatio
//             }
//           }
//           promotionTeaserImageRightBottom {
//             fluid {
//               src
//               srcSet
//               srcWebp
//               srcSetWebp
//               sizes
//               aspectRatio
//             }
//           }
//           promotionTeaserTitleImage {
//             fluid {
//               src
//               srcSet
//               srcWebp
//               srcSetWebp
//               sizes
//               aspectRatio
//             }
//           }
//           promotionTeaserImageLeft {
//             fluid {
//               src
//               srcSet
//               srcWebp
//               srcSetWebp
//               sizes
//               aspectRatio
//             }
//           }
//           promotionTeaserImageBottomLeft {
//             fluid {
//               src
//               srcSet
//               srcWebp
//               srcSetWebp
//               sizes
//               aspectRatio
//             }
//           }
//           promotionTeaserImageBottomRight {
//             fluid {
//               src
//               srcSet
//               srcWebp
//               srcSetWebp
//               sizes
//               aspectRatio
//             }
//           }
//           promotionTeaserContent {
//             json
//           }
//         }
//       }
//     }
//   }

//   allInstaNode {
//     edges {
//       node {
//         id
//         likes
//         comments
//         mediaType
//         preview
//         original
//         timestamp
//         caption
//         localFile {
//           childImageSharp {
//             fluid {
//               src
//               srcSet
//               srcWebp
//               srcSetWebp
//               sizes
//               aspectRatio
//             }
//           }
//         }
//         # Only available with the public api scraper
//         thumbnails {
//           src
//           config_width
//           config_height
//         }
//         dimensions {
//           height
//           width
//         }
//       }
//     }
//   }
// }
// `